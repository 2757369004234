import './index.scss'
import './remove_card_basket'

const hamburger = document.querySelector('#hamburger')
const header = document.querySelector('.header')

hamburger.addEventListener('click', () => header.classList.toggle('active'))

document.querySelector('.search__btn-open').addEventListener("click", function () {
    const search_input = document.querySelector(".search__input")
    location.href = `/search/?q=${search_input.value}`
})

document.querySelector(".search__input").addEventListener("keydown", function (e) {
    if (e.target.value !== "" && e.keyCode === 13) {
        const search_input = document.querySelector(".search__input")
        location.href = `/search/?q=${search_input.value}`
    }
})

document.querySelector('.search__btn-mob').addEventListener('click', () => header.classList.add('search'))
document.querySelector('.search__btn-close').addEventListener('click', () => header.classList.remove('search'))


const select_lang = document.querySelector('#select_lang')

select_lang.addEventListener('change', () => {
    window.location = `/set_lang/${select_lang.value}`
})