import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./index.scss";

Swiper.use([Autoplay, Navigation, Pagination, Scrollbar]);

const HomeBanner_swiper = new Swiper(".home__banner-swiper", {
  slidesPerView: "1",
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  loop: "true",

  navigation: {
    prevEl: ".home__banner-button-prev",
    nextEl: ".home__banner-button-next",
  },
});

const contactFormNumbers = document.querySelectorAll(".contacts-phone");
contactFormNumbers.forEach((nubmer) =>
  nubmer.addEventListener("input", function (e) {
    let input = e.target;
    let value = input.value.replace(/\D/g, "");
    let formattedValue = "";

    if (value.length > 0) {
      formattedValue += "(" + value.substring(0, 3);
    }
    if (value.length >= 4) {
      formattedValue += ") " + value.substring(3, 6);
    }
    if (value.length >= 7) {
      formattedValue += "-" + value.substring(6, 10);
    }

    input.value = formattedValue;
  })
);
