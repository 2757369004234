import { bad_modal } from "@component/module/form_action";
import { config, csrf } from "./instance";

export async function add_card_api(body) {
  try {
    const response = await fetch(
      "/api/cart/cart_product/",
      config("POST", body)
    );
    if (response.ok) {
      return response.json();
    } else {
      bad_modal(response.statusText);
    }
  } catch (error) {
    bad_modal(error);
  }
}

export async function remove_card_api(id) {
  try {
    const response = await fetch(`/api/cart/cart_product/${id}/`, {
      method: "DELETE",
      headers: {
        "X-CSRFToken": csrf,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      bad_modal(response.statusText);
    }
  } catch (error) {
    bad_modal(error);
  }
}

export async function update_card_api(id, quantity) {
  try {
    const response = await fetch(
      `/api/cart/cart_product/${id}/`,
      config("PATCH", { quantity })
    );
    if (response.ok) {
      return response.json();
    } else {
      bad_modal(response.statusText);
    }
  } catch (error) {
    bad_modal(error);
  }
}

export async function get_cardId_api() {
    try {
      const response = await fetch(
        `/api/cart/get_id/`,
        config("GET")
      );
      if (response.ok) {
        return response.json();
      } else {
        bad_modal(response.statusText);
      }
    } catch (error) {
      bad_modal(error);
    }
}
