import { remove_card_api } from "../../../api/basket.api"

document.body.addEventListener('click', async (e) => {
    const target = e.target

    if (target.className === 'btn-remove') {
        const {total_price, total_quantity} = await remove_card_api(target.dataset.id)

        document.querySelectorAll('.total_price__value').forEach(total => total.textContent = total_price)
        target.closest('.cart_product').remove()

        if (document.querySelector('.total_quantity')) {
            document.querySelector('.total_quantity').textContent = total_quantity
        }
    }
})

