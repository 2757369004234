import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./index.scss";

Swiper.use([Autoplay, Navigation, Pagination, Scrollbar]);

const banner_swiper = new Swiper(".banner", {
  slidesPerView: "1",
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  on: {
    slideChange: function () {
      // Отримуємо активний слайд
      const activeSlide = this.slides[this.activeIndex];

      // Знаходимо текст у активному слайді
      const textElement = activeSlide.querySelector(".banner__text");

      // Змінюємо видимість тексту на видимий
      textElement.style.visibility = "visible";
    },
  },


  navigation: {
    prevEl: ".banner-button-prev",
    nextEl: ".banner-button-next",
  },

  // modules: [Autoplay, Navigation, Pagination, Scrollbar],

  // pagination: {
  //     el: ".top_slider-pagination",
  //     type: "fraction",
  //     renderFraction: function (currentClass, totalClass) {
  //         return `<div class="pagination_wrapper">
  //             <span class="currentZero">0</span><span class="${currentClass}"></span>
  //             <span class="totalZero">/0</span><span class="${totalClass}"></span>
  //         </div>`
  //     }
  // },
});

const projects_swiper = new Swiper(".projects-swiper", {
  // loop: true,
  spaceBetween: 30,
  slidesPerView: "auto",
  centerInsufficientSlides: true,

  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },

  navigation: {
    nextEl: ".projects-button-next",
    prevEl: ".projects-button-prev",
  },
});

const items_swiper = new Swiper(".items-swiper", {
  //loop: true,
  spaceBetween: 18,

  navigation: {
    nextEl: ".items-button-next",
    prevEl: ".items-button-prev",
  },

  breakpoints: {
    1200: {
      slidesPerView: "5",
    },
    1000: {
      slidesPerView: "4",
    },
    800: {
      slidesPerView: "3",
    },
    600: {
      slidesPerView: "2",
    },
    300: {
      slidesPerView: "1",
    },
  },
});
