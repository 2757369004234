import "./index.scss";
import validator from "validator";
import { current_language } from "../utils";

export default function validation(validation_btn) {
  // validation_btn - кнопка форми яка відправляє дані

  // змінна для зберігання помилок
  let count_error = 0;

  // змінна де зберігаються всі типи інпутів з текстом помилки та перевірками
  let input_type = {
    required: {
      error: {
        es: "¡Este campo es obligatorio!",
        ru: "Это поле обязательно для заполнения!",
        en: "This field is required!",
      },
      check: (value) => {
        if (value.length != 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    email: {
      error: {
        es: "¡La dirección de correo electrónico es incorrecta!",
        ru: "Почта введена неверно!",
        en: "Mail entered incorrectly!",
      },
      check: validator.isEmail,
    },
    name: {
      error: {
        es: "¡El nombre se ha introducido incorrectamente!",
        ru: "Имя введено неверно!",
        en: "The name was entered incorrectly!",
      },
      check: (value) => {
        let check = validator.isNumeric(value);
        if (!check) {
          return true;
        } else {
          return false;
        }
      },
    },
    phone: {
      error: {
        es: "¡El número de teléfono se ingresó incorrectamente!",
        ru: "Телефон введен неверно!",
        en: "The phone was entered incorrectly!",
      },
        check: (value) => {
        return validator.isStrongPassword(value, {
          minLength: 10,
          maxLength: 10,
          minLowercase: 0,
          minUppercase: 0,
          minNumbers: 10,
          minSymbols: 0,
          returnScore: false,
          pointsPerUnique: 0,
          pointsPerRepeat: 0,
          pointsForContainingLower: 0,
          pointsForContainingUpper: 0,
          pointsForContainingNumber: 0,
          pointsForContainingSymbol: 0,
        });
      },
    },
    comment: {
      error: {
        es: "¡Este campo es obligatorio!",
        ru: "Это поле обязательно для заполнения!",
        en: "This field is required!",
      },
      check: (value) => {
        return validator.isStrongPassword(value, {
          minLength: 1,
          minLowercase: 0,
          minUppercase: 0,
          minNumbers: 0,
          minSymbols: 0,
          returnScore: false,
          pointsPerUnique: 0,
          pointsPerRepeat: 0,
          pointsForContainingLower: 0,
          pointsForContainingUpper: 0,
          pointsForContainingNumber: 0,
          pointsForContainingSymbol: 0,
        });
      },
    },
    password: {
      error: {
        es: "¡La contraseña debe contener más de 6 caracteres!",
        ru: "Пароль должен содержать более шести символов!",
        en: "The password must contain more than 6 characters!",
      },
      check: (value) => {
        return validator.isStrongPassword(value, {
          minLength: 6,
          minLowercase: 0,
          minUppercase: 0,
          minNumbers: 0,
          minSymbols: 0,
          returnScore: false,
          pointsPerUnique: 0,
          pointsPerRepeat: 0,
          pointsForContainingLower: 0,
          pointsForContainingUpper: 0,
          pointsForContainingNumber: 0,
          pointsForContainingSymbol: 0,
        });
      },
    },
    password1: {
      error: {
        es: "¡La contraseña debe contener más de 6 caracteres!",
        ru: "Пароль должен содержать более шести символов!",
        en: "The password must contain more than 6 characters!",
      },
      check: (value) => {
        return validator.isStrongPassword(value, {
          minLength: 6,
          minLowercase: 0,
          minUppercase: 0,
          minNumbers: 0,
          minSymbols: 0,
          returnScore: false,
          pointsPerUnique: 0,
          pointsPerRepeat: 0,
          pointsForContainingLower: 0,
          pointsForContainingUpper: 0,
          pointsForContainingNumber: 0,
          pointsForContainingSymbol: 0,
        });
      },
    },
    password2: {
      error: {
        es: "¡Las contraseñas no coinciden!",
        ru: "Пароли не совпадают!",
        en: "Passwords do not match!",
      },
      check: (str) => {
        let comparison = document.querySelector('[data-type="password1"]');
        return validator.equals(str, comparison.value);
      },
    },
  };

  if (validation_btn != null) {
    // обгортка всієї форми
    let wrapper = validation_btn.closest(".validation__block");
    // всі інпути які потрібно провалідувати
    let all_input = wrapper.querySelectorAll(".validation_input");

    // перебір кожного інпута окремо
    all_input.forEach((element) => {
      // обгортка для інпута
      let container = element.closest(".validation_container");
      // пошук в дата атрибуті типу інпуту
      let type = element.dataset.type;
      // додаткова перевірка, на всяк випадок якщо є необхідність
      // динамічно міняти валідацію конкретного інпута
      let required = element.dataset.required;
      // дані які ввів користувач в інпут
      let value = element.value;

      // якщо перевірка по типу спрацювала то все ок,
      // в іншому випадку до змінної з помилкою додається 1
      if (type === "phone") {
        if (input_type[type].check(`${value}`) && value.length >= 10) {
          remove_error(container);
        } else {
          create_error(container, input_type[type].error[current_language]);
          count_error += 1;
        }
      } else {
        if (
          (input_type[type].check(`${value}`) && value.length >= 1) ||
          required == "false"
        ) {
          remove_error(container);
        } else {
          create_error(container, input_type[type].error[current_language]);
          count_error += 1;
        }
      }

      if (element.dataset.event != "active") {
        element.addEventListener("change", function (e) {
          let change_value = e.target.value;
          element.dataset.event = "active";
          if (type === "phone") {
            if (
              input_type[type].check(`${change_value}`) &&
              change_value.length >= 10
            ) {
              let error = container.querySelector(".validation_error");
              if (error != null) {
                count_error -= 1;
                remove_error(container);
              }
            } else {
              create_error(container, input_type[type].error[current_language]);
              count_error += 1;
            }
          } else {
            if (
              (input_type[type].check(`${change_value}`) &&
                change_value.length >= 1) ||
              required == "false"
            ) {
              let error = container.querySelector(".validation_error");
              if (error != null) {
                count_error -= 1;
                remove_error(container);
              }
            } else {
              create_error(container, input_type[type].error[current_language]);
              count_error += 1;
            }
          }
        });
      }
    });

    if (document.querySelector(".conditions_container")) {
      const conditions_container = document.querySelector(
        ".conditions_container"
      );
      if (!document.querySelector("#conditions").checked) {
        count_error += 1;
        create_error(
          conditions_container,
          input_type.required.error[current_language]
        );
      } else {
        remove_error(conditions_container);
      }
    }
  }

  // якщо помилок не було, до кнопки додається дата атрибут true
  if (count_error == 0) {
    return true;
  } else {
    return false;
  }

  // створює блок з помилкою
  function create_error(container, text) {
    let check = container.querySelector(".validation_error");
    if (check == null) {
      let error = document.createElement("div");
      error.className = "validation_error";
      error.textContent = text;
      container.append(error);
    }
  }

  // видаляє блок з помилкою
  function remove_error(container) {
    let check = container.querySelector(".validation_error");
    if (check != null) {
      container.querySelector(".validation_error").remove();
    }
  }
}
