import "./index.scss";
import validation from "../../module/validation/index";

form_send(".contact-us__form")
form_send(".form__quick-call")
form_send(".order_form")

function form_send(wrapper) {
    const form_wrapper = document.querySelectorAll(wrapper);
    const loader = document.querySelector(".modal_loading__block");

    form_wrapper.forEach((element) => {
        const action = element.getAttribute("action");

        const btn = element.querySelector(".validation_btn");
        if (btn) {
            btn.addEventListener("click", function (e) {
                e.preventDefault();
                const status = validation(btn);

                if (status == true) {
                    const elements = element.elements;
                    console.log(elements);
                    const obj = {};

                    for (let i = 0; i < elements.length; i++) {
                        const item = elements.item(i);
                        const check_type = ["file", "reset", "submit", "button", "radio", "checkbox"].indexOf(item.type);

                        if (item.type === "radio" && item.checked) {
                            obj[item.name] = item.value;
                        } else if (check_type == -1) {
                            obj[item.name] = item.value;
                        }
                    }

                    console.log(obj);

                    if (action) {
                        loader.classList.add("active");
                        fetch(action, {
                                method: "POST",
                                body: new URLSearchParams(obj),
                                // body: new URLSearchParams(obj).toString(),
                            })
                            .then((response) => {
                                if (response.ok) {
                                    accept_modal()
                                    return response.json();
                                } else {
                                    bad_modal(response.statusText);
                                }
                            }).then((data) => {
                                if (data.url) {
                                    window.location = data.url
                                }
                            })
                            .catch((error) => {
                                bad_modal(error);
                            });
                    }
                } else {
                    console.log("error!");
                }
            });
        } else {
            console.error(`такого модального вікна не існує на цій сторінці - ${wrapper}`);
        }
    });
}

export function bad_modal(error_message) {
    const loader = document.querySelector(".modal_loading__block");
    const bad = document.querySelector(".modal_bad__block");
    const error_block = bad.querySelector('.modal_bad_text')

    if (error_message) {
        console.log("error_message: ", error_message);
        error_block.textContent = error_message
    }

    setTimeout(() => {
        loader.classList.remove("active");
        bad.classList.add("active");
    }, 500);
    setTimeout(() => {
        bad.classList.remove("active");
        error_block.textContent = ''
    }, 2000);
}

function accept_modal() {
    let loader = document.querySelector(".modal_loading__block");
    let accept = document.querySelector(".modal_accept__block");
    let inputs = document.querySelectorAll('.validation_input')

    setTimeout(() => {
        loader.classList.remove("active");
        accept.classList.add("active");
    }, 0);
    setTimeout(() => {
        accept.classList.remove("active");
    }, 4000);

    inputs.forEach(input => input.value = '')
}